import { META_CARD_BASE_PATH } from "./config";
import {
  blogPageUrl,
  helpPageUrl,
  aboutPageUrl,
  pressPageUrl,
  guidesPageUrl,
  retailPageUrl,
  capitalPageUrl,
  careersPageUrl,
  whyBrassPageUrl,
  customersPageUrl,
  privacyPolicyPageUrl,
  termsOfServicePageUrl,
  internetCompaniesPageUrl,
  brassCapitalTermsPageUrl,
  homePageUrl,
  ebookPageUrl,
  launchpadHelpPageUrl,
  launchpadPageUrl,
  payrollPageUrl,
  cardsTermsAndConditionsPageUrl,
  savingsPageUrl,
} from "./pageUrl";

export default {
  home: {
    title: "Home",
    slug: homePageUrl,
    description: "Get started with a modern current account",
    image: `${META_CARD_BASE_PATH}/homepage.png`,
  },
  about: {
    title: "About us",
    slug: aboutPageUrl,
    description: "Learn more about our mission",
  },
  termsOfService: {
    title: "Terms of Use",
    slug: termsOfServicePageUrl,
  },
  privacy: {
    title: "Privacy policy",
    slug: privacyPolicyPageUrl,
  },
  capital: {
    title: "Capital",
    slug: capitalPageUrl,
    image: `${META_CARD_BASE_PATH}/capital.png`,
  },
  brassCapitalTerms: {
    title: "Capital Terms of Service",
    slug: brassCapitalTermsPageUrl,
  },
  cardsTermsAndConditions: {
    title: "Cards Terms and Conditions",
    slug: cardsTermsAndConditionsPageUrl,
  },
  blog: {
    title: "Blog",
    slug: blogPageUrl,
    description: "Stay updated on news and products",
    image: `${META_CARD_BASE_PATH}/blog.png`,
  },
  customers: {
    title: "Customers",
    slug: customersPageUrl,
    description: "Our Customers",
  },
  retail: {
    title: "Retail businesses",
    slug: retailPageUrl,
    description: "Run your retail business on a better current account",
    image: `${META_CARD_BASE_PATH}/retail-business.png`,
  },
  internetCompanies: {
    title: "Internet companies",
    slug: internetCompaniesPageUrl,
    description: "Startups, Online, API driven companies",
    image: `${META_CARD_BASE_PATH}/internet-business.png`,
  },
  whyBrass: {
    title: "Why Brass?",
    slug: whyBrassPageUrl,
    description: "Our story and our path forward",
  },
  careers: {
    title: "Careers",
    slug: careersPageUrl,
    description: "Explore opportunities with us",
    image: `${META_CARD_BASE_PATH}/career.png`,
  },
  press: {
    title: "Press resources",
    slug: pressPageUrl,
    description: "Press assets and guidelines",
  },
  help: {
    title: "Help",
    slug: helpPageUrl,
    description: "Get support for your business",
  },
  guides: {
    title: "Guides",
    slug: guidesPageUrl,
    description: "Everything your business needs to know",
  },
  launchpad: {
    title: "Brass Launchpad",
    slug: launchpadPageUrl,
    description: "Turn your idea to a business in days",
    image: `${META_CARD_BASE_PATH}/launchpad.png`,
  },
  launchpadHelp: {
    title: "Launchpad help",
    slug: launchpadHelpPageUrl,
    description: "Get support for your business",
  },
  ebook: {
    title: "SME Guide Book",
    slug: ebookPageUrl,
    description: "The ultimate guide book for your business",
  },
  payroll: {
    title: "Payroll",
    slug: payrollPageUrl,
    description:
      "Get started with a simple payroll service built to scale with your business and team needs.",
    image: `${META_CARD_BASE_PATH}/payroll.png`,
  },
  savings: {
    title: "Savings",
    slug: savingsPageUrl,
    description: "Get closer to your business goals with Brass savings",
  },
};
